<template lang="pug">
  v-autocomplete(
    v-on:keydown.enter.prevent
    prepend-inner-icon="mdi-magnify"
    filled
    outlined
    :loading="areProductsPending"
    :items="products"
    placeholder="Add Part #"
    hide-details
    hide-selected
    v-model="searchResult"
    dense
    return-object
    clearable
    autofocus
    auto-select-first
    @change="pickProduct($event)"
    :value="value"
    item-text="name"
    item-value="id"
    :rules="rules ? rules : null"
    :search-input.sync="searchString"
  )
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'

export default {
  name: 'ProductsAutocomplete',
  props: {
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String
    },
    shopId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const searchString = ref('')
    const searchResult = ref(null)

    const { Product } = context.root.$FeathersVuex.api

    const query = {
      $or: [
        {
          shop_id: props.shopId
        },
        {
          type: 'GLOBAL'
        }
      ],
      $limit: 10
    }

    const productsParams = computed(() => {
      const params = { ...query }

      if (searchString.value) {
        params.name = {
          $regex: searchString.value,
          $options: 'gi'
        }
      }

      return { query: params }
    })

    const productsFetchParams = computed(() => {
      const params = { ...query }

      if (searchString.value) {
        params.name = {
          $iRegexp: searchString.value
        }
      }

      return { query: params }
    })

    const { items: products, isPending: areProductsPending } = useFind({
      model: Product,
      fetchParams: productsFetchParams,
      params: productsParams
    })

    return {
      searchString,
      searchResult,
      products,
      areProductsPending
    }
  },
  methods: {
    pickProduct (product) {
      if (product) {
        this.$emit('change', { product })

        this.$nextTick(() => {
          this.searchResult = ''
          this.searchString = ''
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
